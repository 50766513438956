import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Les fructificacions d’aquest bolet són de 10 a 15 cm d’altura, amb la part superior piramidal i buida. Presenta costelles paral·leles transversals que formen alvèols de color marró. Les costelles s’ennegreixen amb l’edat. Destaca el peu de la resta pel seu color blanc, però és llis i buit com el capell. Les espores són el·líptiques, de 18-25 x 11-15 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      